div.send-documents-container > div > div.MuiDialog-paper {
  overflow-x: hidden;
  width: auto !important;
  padding: 9px 20px 9px 20px;
  min-width: 500px !important; }

.send-documents-container-first-row {
  place-content: space-between; }

.send-documents-heading {
  font-family: "MontSerrat", sans-serif;
  font-weight: 600;
  color: #262626;
  font-size: 24px; }

.close-btn-send-documents {
  top: -10px;
  right: -17px; }

.send-btn-documents {
  margin-right: 10px; }

.send-doc-option-container {
  align-self: center;
  padding: 2px 3px 6px 0px;
  width: 100%; }

.send-email-editor-top {
  display: flex;
  /* align-items: center; */
  /* .send-email-editor-top-left {
    flex: 1;
    font-size: 14px;

    font-weight: 600;
  } */
  /* .send-email-editor-top-right {
    .send-email-editor-top-right-span {
      font-size: 16px;
      color: #000;
      margin-right: 8px;
    }
    .send-email-editor-top-right-select {
      .MuiInput-input {
        font-size: 18px;
        color: black;
      }
    }
  } */ }

.send-doc-btn {
  margin-bottom: 10px;
  width: 100%;
  /* border: 4px solid #ef7c01 !important; */
  background-color: #ef7c01;
  border-radius: 5px !important;
  padding: 7px 10px 7px 10px;
  color: white;
  font-weight: 600;
  height: 30px; }

.box-flex-send-doc {
  display: flex;
  justify-content: space-between; }

.email-heading {
  font-weight: 600;
  font-size: 14px; }

.doucment-email-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* or 26px */
  color: #26284d; }

.width-full-item-document-email {
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid; }

.full-width {
  width: 100%; }

.doucment-email-label {
  color: grey; }

.item-document-email-row {
  padding-top: 10px;
  display: flex;
  justify-content: space-between; }

.font-label-select {
  font-size: 14px;
  font-weight: 600; }

.document-email-item {
  padding-bottom: 10px; }

.sms-body {
  background: whitesmoke;
  font-size: 19px;
  line-height: 26px;
  padding: 10px 10px 10px 10px; }

.send-email-editor-bottom {
  display: flex;
  justify-content: space-between; }
