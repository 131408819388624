.container-chief-complaints-buttons {
  padding: 1rem 0.5rem 0rem 0.5rem;
  border-bottom: 1px solid #d8d8d8; }

.container-chief-complaints-data {
  height: 41rem;
  padding: 0rem 0rem 1rem 0.5rem; }

.container-chief-complaints-data-str {
  overflow-y: scroll;
  height: 39rem; }

.chief-complaint-item {
  overflow-y: scroll;
  height: 38rem; }

.item-chief-complaint {
  padding: 1rem;
  border: 1px #cccaca solid;
  overflow-wrap: break-word;
  border-radius: 5px; }

.container-add-button-and-heading {
  display: inline-flex;
  justify-content: space-between;
  top: 0;
  left: 1rem; }

.editor-container {
  overflow-y: scroll; }

.container-add-button-and-heading {
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 1rem; }

.timeStamp {
  font-size: 10px;
  color: grey; }

.subindex {
  padding: 10px; }

.chiefComplaintScroll {
  overflow-y: scroll;
  overflow-x: hidden; }

::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d5d5; }

::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d5d5; }
