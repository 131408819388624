.field-inline {
  padding: 0rem;
  line-height: 1.5; }

.field-hidden-date {
  display: none; }

.title-tracking-day {
  margin-top: 8px; }

.pending-save {
  background: rgba(255, 110, 64, 0.2); }
