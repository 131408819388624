.container-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.brand {
  vertical-align: middle;
  height: 32px; }

.brand-text {
  padding-left: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  color: #262626;
  margin-top: 16px; }

.container-user {
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 1rem; }

.avatar-user {
  background-color: #ffffff;
  vertical-align: middle; }

.container-client-brand {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding-right: 10px; }
  .container-client-brand .profile-container {
    flex: 1;
    display: inline-flex; }
    .container-client-brand .profile-container a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.87); }
    .container-client-brand .profile-container .img-container {
      display: flex;
      justify-content: center; }
      .container-client-brand .profile-container .img-container img {
        width: 40px; }
    .container-client-brand .profile-container .doctor-user {
      padding-left: 10px;
      height: 40px;
      overflow: hidden;
      justify-content: flex-start;
      display: flex;
      flex-direction: column; }
      .container-client-brand .profile-container .doctor-user .doctor-user-name {
        font-size: 15px;
        font-weight: 500; }
      .container-client-brand .profile-container .doctor-user .doctor-user-title {
        font-size: 12px; }
  .container-client-brand .setting-container {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end; }
    @media screen and (max-width: 380px) {
      .container-client-brand .setting-container .client-name {
        display: none;
        visibility: hidden;
        width: 0px; } }
    .container-client-brand .setting-container .client-name .brand-text {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 1em;
      color: #262626;
      width: 180px;
      height: 29px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 0px; }
    .container-client-brand .setting-container .logout-link {
      text-align: right;
      width: 100%;
      justify-content: flex-end;
      cursor: pointer; }
  .container-client-brand .p-15 {
    padding: 15px; }
  .container-client-brand .no-mg {
    margin-left: 0px;
    margin-right: 0px; }
