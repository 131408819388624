.sec-patient-tracking-info {
  padding: 1rem; }

.item-param {
  padding: 0.5rem; }
  .item-param small {
    color: #ababab; }

.item-checklist {
  padding: 0.5rem; }

.highlight {
  background: rgba(255, 229, 100, 0.3); }

.tiny {
  font-size: 0.75rem; }
