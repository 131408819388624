h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "MontSerrat", sans-serif;
  font-weight: 700;
  color: #262626; }

.loader-global {
  width: 100%;
  position: fixed;
  top: 49px; }

.primary {
  color: #00c9d3; }

.secondary {
  color: #ef7c01; }

.tertiary {
  color: #7479fb; }

.bg-tertiary {
  background-color: #7479fb; }

body::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em; }

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

body::-webkit-scrollbar-thumb {
  background-color: #00c9d3 !important;
  border-radius: 5px;
  outline: 1px solid #ededed; }

.material-icons {
  vertical-align: middle; }

.container-main {
  width: 100%; }

.clickable {
  cursor: pointer; }

.select-button-form {
  margin-left: 3rem;
  margin-top: 2rem;
  width: 80%; }

.date-button-form {
  width: 100%; }

.white {
  color: #ffffff !important; }

.bg-white {
  background-color: #ffffff !important; }

.disabled-item {
  opacity: 0.5;
  pointer-events: none; }

.text-capitalize {
  text-transform: capitalize; }

.d-none {
  display: none; }
