.item-call-note {
  padding: 0.5rem; }

.item-call-note .btn-action-email-call-note {
  margin-bottom: 0.5rem; }

.dropbtn {
  background-color: white;
  color: black;
  padding: 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 12px; }

.success-unsuccess-dropdown {
  width: 100%; }

.unselected-callnote-btn {
  background-color: #00c9d3;
  color: white;
  width: 100%; }

.selected-callnote-btn {
  background-color: #ef7c01;
  color: white;
  width: 100%; }
