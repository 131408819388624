.item-medication {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f2fcfd; }

.content-medication li {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase; }

.content-medication * {
  background-color: #f2fcfd !important; }

.container-medications {
  /* width: 100%; */
  height: 45rem;
  overflow-y: scroll;
  padding-left: 1rem; }

.container-medications-data {
  height: 45rem;
  padding: 1rem;
  overflow: scroll; }

.medication-notes {
  height: 45rem;
  overflow-y: scroll; }

.container-header-and-button {
  display: flex;
  justify-content: space-between; }

.new-medication {
  background-color: red;
  color: white; }

/* .container-editor{
    overflow-y: scroll;
  } */
::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d5d5; }
