.main-component-sort {
  margin-left: 14px;
  width: 100%; }

.sort-positioned-menu > div > ul {
  width: 95px; }

.space-two-items {
  justify-content: space-between; }

.sort-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #000000; }

.left-space-heading {
  margin-left: 10px; }

#sort-positioned-menu > .MuiMenu-paper {
  width: 200px;
  border-radius: 8px; }

.main-component-sort > div {
  width: 100%; }

.full-width-compenent {
  width: 100%; }

.grey-sort-border {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 7px;
  margin-right: 8px;
  margin-left: 8px; }

.dragIcon {
  font-size: 20px;
  font-weight: 700;
  margin-top: -1px;
  margin-right: 9px; }

.sort-items {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170.4%;
  /* or 17px */
  letter-spacing: -0.03em;
  color: #000000; }

.highlight-item-sort {
  font-weight: 800; }
